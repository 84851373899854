@import url('https://fonts.googleapis.com/css2?family=Delicious+Handrawn&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto');

body {
  margin: 0;
  font-family: 'Cabin', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: aliceblue !important;
  padding-bottom: 20px;
  color: #254c90;
}


.logoName {
  font-family: "Delicious Handrawn", cursive;
  font-size: 3.8em;
  font-weight: bold;
  font-style: normal;
  text-align: center;
  color: #254c90;
}

.light-theme {
  background-color: whitesmoke;
  color: #333;
}

.dark-theme {
  background-color: #333;
  color: whitesmoke;
}

.offcanvas-bottom {
  height: auto !important;
}

.itemCount {
  z-index: 10;
  top: 9rem;
  font-size: 3rem;
  text-align: center;
  font-weight: bold;
}

a {
  color: #254c90 !important;
}

nav a {
  padding: 5px;
}

.topNav {
  text-align: right;
  padding: 10px 20px;
}

.feedback a {
  font-size: 1.3em;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Cabin", sans-serif;
  color: #254c90 !important;
  line-height: 1.3em !important;
}

.section-center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  border: 1px solid #2d2d2d;
  margin-bottom: 12px;
}

.bucketIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  /* background-image: url("../images/bucket.svg"); */
  /* background-repeat: no-repeat;
  background-position: 50% ; */
  /* background-size:contain; */
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
  height: 70%;
}

.hopeCount {
  position: relative;
  width: 200px;
  top: 200px;
  font-weight: 800;
}

.no-wrap {
  flex: 0 0 auto;
}

.list-group {
  padding-left: 50px;
  display: inherit;
}

.list-group-item.list-group-item-light {
  border: 2px solid #999 !important;
  margin-bottom: 6px;
}

.hopeItem {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  word-break: break-word;
  padding: 10px;
  font-size: 1.2em;
}

.closeX {
  font-style: bold;
  text-align: left;
  font-weight: 700;
}

.newsletter-button {
  position: absolute;
  z-index: 1000;
  bottom: 10px;
  right: 10px;
}

.btn.startButton {
  min-width: 200px;
}

.btn-primary {
  background-color: #0a58ca !important;
}

.form-control {
  border: 2px solid !important;
}

.textWhite {
  color: #fff !important;
  text-decoration: none;
}


@media screen and (min-width:768px) {
  .hopeCount.text-center {
    position: relative;
    top: 175px;
  }
}